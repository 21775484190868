import React from "react"
import styles from "./planning.styles.module.css"
import Button from "src/components/shared/Button"
import Layout, { Inner } from "src/components/shared/Layout"
import Typography from "src/components/shared/Typography"
import SEO from "src/components/shared/seo"
import { useStaticQuery, graphql } from "gatsby"
import Title from "../components/shared/Title"
import Wave from "src/components/shared/Wave"

const planningQuery = graphql`
  query PlanningTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "planning" } }) {
      html
      frontmatter {
        image
        pdf
      }
    }
  }
`

export const PlanningTemplate = ({ preview, image, pdf, html }) => (
  <Layout>
    <Inner>
      <SEO title="Planning — Turtle Tonic — Aquabike • Aquagym • Leçon de natation" />
      <Title>Le planning</Title>
      <div className={styles.Buttons}>
        <Button target="_blank" href={pdf}>
          Télécharger le planning (PDF)
        </Button>
        <Button href="/contact" bordered className={styles.BorderedButton}>
          Une question ?
        </Button>
      </div>

      {!!preview ? (
        <Typography.p primary>{html}</Typography.p>
      ) : (
        <div
          className={styles.Paragraph}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      )}

      <div className={styles.Container}>
        <div className={styles.Planning}>
          <img src={image} alt="planning" />
        </div>
        <div className={styles.Wave}>
          <Wave />
        </div>
      </div>
    </Inner>
  </Layout>
)

export default () => {
  const data = useStaticQuery(planningQuery)
  const {
    markdownRemark: {
      frontmatter: { pdf, image },
      html,
    },
  } = data

  return <PlanningTemplate pdf={pdf} image={image} html={html} />
}
